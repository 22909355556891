import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {CanComponentDeactivate} from './can-deactivate.interface';
import {Location} from '@angular/common';

@Injectable()
export class CanDeactivateGuard  {
  constructor(private router: Router, private location: Location) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canDeactivate = component.canDeactivate();

    if (!canDeactivate) {
      this.location.go(currentState.url);
    }

    return canDeactivate;
  }
}
